
    import axios from 'axios'
    import store from '../store/index.js'

    export default {
        async add_notifications(params)  {
            return await axios.post('notifications/create.php' , params)
            .then(r =>{
                return r
            })
            .catch(e =>{
                console.log(e);
            })
        },
        async get_notifications()  {
            // return await axios.get('notifications/read.php')
            // .then(r =>{
            //     return r
            // })
            // .catch(e =>{
            //     console.log(e);
            // })
            store.commit('get_notifications')
        },
        async delete_notifications(notification_id)  {
            return await axios.post('notifications/delete.php' , {
                notification_id : notification_id
            })
            .then(r =>{
                return r
            })
            .catch(e =>{
                console.log(e);
            })
        },
        async getOne(notification_id)  {
            return await axios.post('notifications/readOne.php' , {
                notification_id : notification_id
            })
            .then(r =>{
                return r
                
            })
            .catch(e =>{
                console.log(e);
            })
        },
        async update_notifications(params)  {
            return await axios.post('notifications/update.php' , params)
            .then(r =>{
                return r
            })
            .catch(e =>{
                console.log(e);
            })
        },
        async report_notifications(params)  {
            return await axios.post('notifications/report.php',params)
            .then(r =>{
                return r
            })
            .catch(e =>{
                console.log(e);
            })
        },
    }
