
    <template>
    <div>
    <v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
        <v-container v-else>
            <form @submit.prevent="add_notifications" autocomplete="off">
                <v-layout row wrap>
        <v-flex xs12 lg2 xl2 md3 sm4>
        <v-text-field v-model="notifications.notification_from" type="number" :label="$store.getters.lang.data.notification_from" outlined
            class="mx-1"  required rounded>
        </v-text-field>
    </v-flex>


        <v-flex xs12 lg2 xl2 md3 sm4>
        <v-text-field v-model="notifications.notification_to" type="number" :label="$store.getters.lang.data.notification_to" outlined
            class="mx-1"  required rounded>
        </v-text-field>
    </v-flex>


        <v-flex xs12 lg2 xl2 md3 sm4>
        <v-text-field v-model="notifications.notification_text" type="text" :label="$store.getters.lang.data.notification_text" outlined
            class="mx-1"  required rounded>
        </v-text-field>
    </v-flex>


        <v-flex xs12 lg2 xl2 md3 sm4>
        <v-text-field v-model="notifications.notification_date" type="date" :label="$store.getters.lang.data.notification_date" outlined
            class="mx-1"  required rounded>
        </v-text-field>
    </v-flex>


        <v-flex xs12 lg2 xl2 md3 sm4>
        <v-text-field v-model="notifications.item_id" type="number" :label="$store.getters.lang.data.item_id" outlined
            class="mx-1"  required rounded>
        </v-text-field>
    </v-flex>


        <v-flex xs12 lg2 xl2 md3 sm4>
        <v-text-field v-model="notifications.comment_id" type="number" :label="$store.getters.lang.data.comment_id" outlined
            class="mx-1"  required rounded>
        </v-text-field>
    </v-flex>


    <v-flex xs12 lg2 xl2 md2 sm4>
                        <v-btn rounded color="primary" type="submit" x-large >{{$store.getters.lang.data.add_btn}} notifications</v-btn>
                    </v-flex>
                </v-layout>
            </form>
            <v-layout row wrap mt-5>
                <v-flex xs12>
                    <v-card>
                        <v-card-title primary-title>
                        {{$store.getters.lang.data.notifications}}
                            <v-spacer></v-spacer>
                            <v-text-field autocomplete="off" v-model="search" append-icon="fas fa-search">
                            </v-text-field>
                        </v-card-title>
                        <v-card-text>
                            <v-data-table :headers="headers" :search="search" :items="rows" class="elevation-0"
                                item-key="notification_id">
                                <template v-slot:[`item.notification_id`]="{ item }">
                                    <div>
                                        <v-btn icon color="error" :disabled="$store.state.auth.user.admin_role != 'admin'" class="mx-1" @click="select_notifications(item)" rounded>
                                            <v-icon>mdi-delete-outline</v-icon>
                                        </v-btn>
                                        <v-btn :to="'/notifications-list/'+item.notification_id" icon color="secondary" class="mx-1"
                                            rounded>
                                            <v-icon>mdi-pencil-outline</v-icon>
                                        </v-btn>
                                    </div>
                                </template>
                            </v-data-table>
                        </v-card-text>
                    </v-card>
                </v-flex>
            </v-layout>
        </v-container>
        <v-dialog v-model="delete_dialog" persistent max-width="290">
            <v-card>
                <v-card-title>
                    {{$store.getters.lang.data.delete_question}}
                </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" class="elevation-0" @click="delete_dialog = false">
                    {{$store.getters.lang.data.cancel_btn}}
                    </v-btn>
                    <v-btn color="green darken-1" text @click="delete_notifications(selected_notifications.notification_id)">
                    {{$store.getters.lang.data.yes_btn}}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-snackbar v-model="snackbar.value" :color="snackbar.color">
            {{snackbar.text}}
        </v-snackbar>
    </div>
</template>
<script>
    import controller from './../../controller/notifications'
    export default {
        data() {
            return {
                notifications: {},
                search: '',
                loading : true,
                snackbar: {
                    value: false,
                    text: '',
                    color: ''
                },
                selected_notifications: {},
                delete_dialog: false,
                headers: [
                    
        {
            text: this.$store.getters.lang.data.notification_id,
            align: 'start',
            sortable: true,
            value: 'notification_id',
        },


        {
            text: this.$store.getters.lang.data.notification_from,
            align: 'start',
            sortable: true,
            value: 'notification_from',
        },


        {
            text: this.$store.getters.lang.data.notification_to,
            align: 'start',
            sortable: true,
            value: 'notification_to',
        },


        {
            text: this.$store.getters.lang.data.notification_text,
            align: 'start',
            sortable: true,
            value: 'notification_text',
        },


        {
            text: this.$store.getters.lang.data.notification_date,
            align: 'start',
            sortable: true,
            value: 'notification_date',
        },


        {
            text: this.$store.getters.lang.data.item_id,
            align: 'start',
            sortable: true,
            value: 'item_id',
        },


        {
            text: this.$store.getters.lang.data.comment_id,
            align: 'start',
            sortable: true,
            value: 'comment_id',
        },


                ],
            }
        },
        computed: {
            rows(){
                return this.$store.getters.notifications
            },
            
            
        },
        mounted(){
            this.read_notifications();
        },
        methods: {
            add_notifications() {
                controller.add_notifications(this.notifications).then(r => {
                    if (r.data) {
                        this.notifications = {}
                        this.$store.state.notifications.push(
                            r.data.new_data
                        )
                        this.snackbar = {
                            value: true,
                            text: 'notifications Added',
                            color: 'success'
                        }
                    } else {
                        this.snackbar = {
                            value: true,
                            text: 'Fail to add notifications',
                            color: 'error'
                        }
                    }
                })
            },
            delete_notifications(notification_id) {
                controller.delete_notifications(notification_id).then(r => {
                    this.delete_dialog = false
                    if (r.data) {
                        this.$store.state.notifications = this.$store.state.notifications.filter(f =>{
                            return f.notification_id != notification_id
                        })
                        this.snackbar = {
                            value: true,
                            text: 'notifications Deleted',
                            color: 'success'
                        }
                    } else {
                        this.snackbar = {
                            value: true,
                            text: 'Delete Faild',
                            color: 'error'
                        }
                    }
                })
            },
            read_notifications() {
                this.loading = true
                controller.get_notifications().then(r => {
                    // this.rows = r.data.rows
                    this.loading = false
                })
            },
            select_notifications(i) {
                this.selected_notifications = i
                this.delete_dialog = true
            },
        },
    }
</script>
